<template>
  <div>
    <el-dialog
      v-model="visible"
      class="purchase-request-modal"
      center
      @close="close"
    >
      <template #header>
        <div class="ar-h1">
          Запрос на вывод средств
        </div>
      </template>

      <el-form>
        <div class="mb2">
          Сумма вывода
        </div>
        <div class="mb5">
          <el-form-item :error="getErrorMessage('amount')">
            <el-input v-model.number="form.amount" size="large" @blur="v$.form.amount.$touch()" />
          </el-form-item>
        </div>

        <div class="ar-h3 mb3">
          Оставьте ваши контакты
        </div>

        <el-form-item :error="getErrorMessage('telegram')">
          <div>
            Telegram
          </div>
          <el-input v-model="form.telegram" size="large" @blur="v$.form.telegram.$touch()" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('vk')" class="mt3">
          <div>
            VK
          </div>
          <el-input v-model="form.vk" size="large" @blur="v$.form.vk.$touch()" />
        </el-form-item>
      </el-form>
      <!-- <div style="display: flex; flex-direction: row; align-items: center;justify-content: space-between; width:100%">
        <div style="font-size: 1em;  flex-basis: 170px;">
          Тариф:
          {{ tarifName }}
        </div>
        <div style="font-size: 1em;">
          1
        </div>
        <div style="font-size: 1.3333333333333333em;">
          {{ price }} ₽
        </div>
      </div> -->

      <template #footer>
        <el-button type="primary" size="large--el-input-inner-height" :disabled="allowSubmit" :loading="loading" @click="sendReques()">
          Подтвердить
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { required, minValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { ElMessage } from 'element-plus';
import { pickBy } from 'lodash';

export default {
  name: 'PurchaseModal',
  props: {
    tarifName: String,
    modelValue: Boolean,
    price: String,
  },
  data: () => ({
    v$: useVuelidate(),
    radio1: 'tg',
    radio2: false,
    allow: false,
    form: {
      amount: 0,
      telegram: null,
      vk: null,
    },
    loading: false,
  }),
  computed: {
    allowSubmit() {
      return this.v$.$invalid;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },

  },
  watch: {
    radio1() {
      this.form.telegram = null;
      this.form.vk = null;
    },
    visible() {
      if (this.visible) {
        this.form = {
          amount: 0,
          telegram: null,
          vk: null,
        };
        this.v$.$reset();
      }
    },
  },
  validations() {
    const form = {
      amount: { required, minValue: minValue(1000) },
    };
    if (this.radio1 === 'tg') {
      form.telegram = { required };
    } else {
      form.vk = { required };
    }
    return { form };
  },
  methods: {
    async sendReques() {
      this.loading = true;
      try {
        const params = pickBy(this.form, (v) => !!v);
        await this.$axios.post('finances/send-withdrawal-request', params);
        ElMessage({ message: 'Запрос отправлен', type: 'success' });
        this.close();
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.visible = false;
    },
    getErrorMessage(fieldName) {
      return this.$errorsMessages(this.v$, `form.${fieldName}`);
    },
  },

};
</script>

<style lang="scss">
  .purchase-request-modal{
    width:36em;

    &__btn{

    }

    .el-form-item{
      margin-bottom:  0.8333333333333334em !important;
    }
    // .el-radio {
    //   --el-color-white:rgb(34, 34, 34);

    //   &__label{
    //     --font-size: 1em;
    //     font-size: 1em!important;
    //     font-weight: 400;
    //   }
    //   &__inner {
    //     --el-radio-input-height:1.3em!important;
    //     --el-radio-input-width:1.3em!important;
    //     height:1.3em!important;
    //     width:1.3em!important;

    //     &::after {
    //       height:0.63em!important;
    //       width:0.63em!important;
    //       left: 50%!important;
    //       top: 50%!important;
    //     }
    //   }
    // }

    &__form-label{
      margin:  0.8333333333333334em 0;
      font-size: 1.1111111111111112em;
      font-weight: 500;
    }

    .el-dialog{
      // &__header{
      //   font-size: 1.7777777777777777em;
      //   color:$primaryBlue;
      //   padding: 2.5555555555555554em 2.5em  0.8333333333333334em 3.5em !important;
      //   text-align: left !important;
      //   margin:0;

      //   &btn{
      //     font-size: 0.8em;
      //     top: 26px !important;
      //     right: 1.6666666666666667em !important;
      //   }
      // }
      &__headerbtn{
        top: 1.3em !important;
        right: 1.3em !important;
      }
      // &__body{
      //   padding:1em 8em 1em 8em!important;
      // }

      &__footer{
        display: flex;
        justify-content: center;
        padding:1em 6em 1em 6em!important;
      }
    }
  }
</style>
