<template>
  <div>
    <div style="" class="finance__stat--wrapper">
      <div class="finance__balance--wrapper ar-card pa4">
        <div class="finance__balance--header ar-h1">
          Баланс
        </div>
        <el-divider />
        <div class="finance__balance--account">
          {{ accountInfo.amount }} ₽
        </div>
        <el-button type="primary" size="large" @click="purchaseRequestModal = true">
          Запрос на вывод
        </el-button>
      </div>
      <div class="finance__balance--wrapper ar-card pa4">
        <div class="finance__balance--header d-flex flex-row justify-space-between">
          <div class="ar-h1">
            Доход
          </div>
          <div class="finance__balance--tag d-flex flex-row align-center">
            Общий доход <span style="transform: translateY(0.2em);"> * </span> Тарифная ставка <span> = </span> Итог
          </div>
        </div>
        <el-divider />
        <div class="finance__balance--row mb3">
          <div>
            Общий доход
          </div>
          <div>
            {{ profitInfo.amount }}  ₽
          </div>
        </div>
        <div class="finance__balance--row mb4">
          <div>
            Тарифная ставка
          </div>
          <div>
            {{ profitInfo.tariffRewardRate*100 }}%
          </div>
          <div class="ml2">
            <el-tooltip
              class="box-item"
              effect="light"
              content="Ставка зависит от тарифа"
              placement="top"
            >
              <el-icon size="1.1em">
                <Question />
              </el-icon>
            </el-tooltip>
          </div>
        </div>
        <div class="finance__balance--row total-price ar-h3">
          <div>
            Итого
          </div>
          <div>
            {{ profitInfo.totalAmount }} ₽
          </div>
        </div>
      </div>
    </div>

    <el-tabs v-model="activeQuart" class="finance__tab" @tab-change="getReportsListByPlatform">
      <el-tab-pane label="Январь - Март" name="1" />
      <el-tab-pane label="Апрель - Июнь" name="2" />
      <el-tab-pane label="Июль - Сентябрь" name="3" />
      <el-tab-pane label="Октябрь - Декабрь" name="4" />
    </el-tabs>

    <div class="finance__table">
      <el-select v-model="platform" class="mb4" size="large" @change="getReportsListByPlatform">
        <el-option
          v-for="platformApi,i in platforms"
          :key="i"
          :label="platformApi"
          :value="platformApi"
        />
      </el-select>
      <div id="list" style="min-height:11.1111111111111112em" class="ar-card">
        <ReportsTable v-if="!loaders.list" :items="paginatedItems" />
      </div>
      <div class="finance__table-pagination--wrapper">
        <div v-if="page>1" class="finance__table-pagination--btn" @click="page--">
          <el-icon>
            <el-icon><ArrowLeft /></el-icon>
          </el-icon>
        </div>
        <div v-if="items.length > 5" class="finance__table-pagination--btn" @click="page++">
          <el-icon>
            <el-icon><ArrowRight /></el-icon>
          </el-icon>
        </div>
      </div>
    </div>
    <PurchaseRequestModal v-model="purchaseRequestModal" />
  </div>
</template>

<script>
import ReportsTable from '@/components/tables/ReportsTable.vue';
import PurchaseRequestModal from '@/components/modals/PurchaseRequestModal.vue';
import {
  ElMessage,
  ElLoading,
} from 'element-plus';
import { mapGetters } from 'vuex';
import {
  Question,
} from '@/assets/icons/index.js';

export default {
  name: 'Finance',
  data: () => ({
    loaders: {
      list: false,
    },
    profitInfo: {},
    accountInfo: {},
    purchaseRequestModal: false,
    balance: '4040',
    activeQuart: '1',
    currentDate: new Date().getFullYear(),
    platforms: [],
    platform: null,
    items: [],
    page: 1,
  }),
  async mounted() {
    this.getProfitInfo();
    this.getUserAccount();
    await this.getPlatforms();
    await this.getReportsListByPlatform();
  },
  methods: {
    async getPlatforms() {
      this.loaders.list = true;
      const loading = ElLoading.service({
        target: '#list',
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
      });
      try {
        const params = {
          year: 2023,
          artist: this.userName,
        };
        const { data } = await this.$axios.get('finances/get-report', { params });
        this.platforms = data.platforms;
        this.platform = this.platforms[0];
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loaders.list = false;
        loading.close();
      }
    },
    async getUserAccount() {
      // const loading = ElLoading.service({
      //   target: '#list',
      //   lock: true,
      //   text: 'Загрузка',
      //   background: 'rgba(0, 0, 0, 0)',
      // });
      try {
        const { data } = await this.$axios.get('user-account/get-factual-balance-by-user');
        this.accountInfo = data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        // this.loaders.list = false;
        // loading.close();
      }
    },
    async getProfitInfo() {
      // const loading = ElLoading.service({
      //   target: '#list',
      //   lock: true,
      //   text: 'Загрузка',
      //   background: 'rgba(0, 0, 0, 0)',
      // });
      try {
        const { data } = await this.$axios.get('finances/get-profit-info');
        this.profitInfo = data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        // this.loaders.list = false;
        // loading.close();
      }
    },
    async getReportsListByPlatform() {
      this.loaders.list = true;
      const loading = ElLoading.service({
        target: '#list',
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
      });
      try {
        const params = {
          year: 2023,
          artist: this.userName,
          platform: this.platform,
          quart: this.activeQuart,
        };
        const { data } = await this.$axios.get('finances/get-report', { params });
        this.page = 1;
        this.items = data.items.map((it, i) => ({ ...it, number: i + 1 }));
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loaders.list = false;
        loading.close();
      }
    },
  },
  computed: {
    ...mapGetters(['userName']),
    paginatedItems() {
      return this.items.slice((this.page - 1) * 5, this.page * 5);
    },
  },
  components: { Question, ReportsTable, PurchaseRequestModal },
};
</script>

<style lang="scss">

  .finance{
    &__tab{
      .el-tabs__header{
      align-items: center;
      justify-content: start;
    }

      .el-tabs__nav-wrap {
        flex:none!important;
      }
    }
    &__stat{
      &--wrapper{
        display: flex;
        flex-basis: 100%;
        margin: 0 -0.8333333333333334em 2.2222222222222223em -0.8333333333333334em;
      }
    }
    &__balance{

      &--tag{
        padding: 0 1cqw;
        border-radius:1em;
        font-size: 0.7em;
        background-color: $subGrey3;

        span {
          margin:0 0.5em;
          color:$primaryBlue;
        }
      }

      &--wrapper {
        margin: 0 0.8333333333333334em;

        flex-direction: column;
        flex-basis: 50%;
      }

      &--account {
        height: 2.7777777777777777em;
        font-size: 1.7777777777777777em;
        font-weight: 500;
      }

      &--row{
        display: flex;

        & :first-child{
          font-weight: 500;
          color:$subGrey1;
          flex-basis: 12rem;
        }

        &.total-price :first-child {
          font-weight: 500;
          color:$primaryGrey;
          flex-basis: 12rem;
        }
      }
    }

    &__table{
      border-radius: 1.9444444444444444em;
      border:1px solid $whiteOpacity;
    }

    &__table-pagination{
      &--wrapper{
        padding-top: 1.3888888888888888em;
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
      &--btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width:2em;
          height: 2em;
          border-radius: 50%;
          padding:5px;
          border:1px solid $whiteOpacity;
          transition: 0.3s;
          margin-left:  0.8333333333333334em;

          &:hover{
            background-color: $whiteOpacity20;
          }
      }
    }
  }
</style>
