<template>
  <div>
    <el-table style="width:100%" :data="items" :default-sort="{ prop: 'position', order: 'descending' }">
      <el-table-column prop="number" label="№" align="center" width="50px" />
      <el-table-column prop="name" label="Название">
        <template #default="scope">
          <div style="display:flex; flex-direction: row">
            <div style="text-align: left; display: block;">
              <div style="font-size: 1em; width:160px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; word-break: break-all;  font-weight: 400;">
                {{ scope.row.trackTitle }}
              </div>
              <div style="font-size: 13px; font-weight: 300;">
                {{ scope.row.isrc }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="artist" label="Артист" align="center" />
      <el-table-column prop="amount" label="Доход" width="98px" align="center" />
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'ReportsTable',
  props: {
    items: Array,
  },
  data: () => ({

  }),
  methods: {

  },
};
</script>
